import React, { useState, useEffect } from "react";
import logo from "../images/logo.svg";
import logoWhite from "../images/logo-white.svg";
import facebook from "../icons/facebook.svg";
import instagram from "../icons/instagram.svg";
import whatsupp from "../icons/whatsupp.svg";
import { NavLink, useLocation } from "react-router-dom";
import "../styles/Header.scss";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const location = useLocation();
  //check if location is changed and if it is make sure that menuOpen is set to false
  useEffect(() => {
    setMenuOpen(false);
  }, [location]);

  return (
    <div className="header">
      <div className="logo">
        <img src={logoWhite} alt="logo" />
      </div>
      <div className="header-rows">
        <div className="header-row">
          <div className="contact-info">
            <a className="contact-info-item" href="tel:+385955323189">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                  clipRule="evenodd"
                />
              </svg>
              <span>+385 91 954 2007 </span>
            </a>
            <a
              className="contact-info-item"
              href="mailto:ddipranjetepiha@gmail.com"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
              </svg>
              <span>ddipranjetepiha@gmail.com</span>
            </a>
          </div>
          <div className="social-media">
            <a
              href="https://www.facebook.com/profile.php?id=100077656701741"
              target="_blank"
            >
              <img src={facebook} alt="facebook" />
            </a>
            <a
              href="https://www.instagram.com/ddipranjetepiha/"
              target="_blank"
            >
              <img src={instagram} alt="instagram" />
            </a>
            <a href="https://wa.me/+385919542007" target="_blank">
              <img src={whatsupp} alt="whatsupp" />
            </a>
          </div>
        </div>
        <div className="header-row">
          <nav className="nav">
            <NavLink to="/" exact activeClassName="active">
              Početna
            </NavLink>
            <NavLink to="/o-nama" activeClassName="active">
              O nama
            </NavLink>
            <NavLink to="/usluge" activeClassName="active">
              Usluge
            </NavLink>
          </nav>{" "}
          <NavLink to="/kontakt" className="button" activeClassName="active">
            Kontakt
          </NavLink>
          <button className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
        </div>
      </div>

      <div className={menuOpen ? "mobile-menu active" : "mobile-menu"}>
        <button
          className={menuOpen ? "close active" : "close"}
          onClick={() => setMenuOpen(!menuOpen)}
        ></button>
        <div className="logo">
          <img src={logoWhite} alt="logo" />
        </div>
        <nav className="nav">
          <NavLink to="/" exact activeClassName="active">
            Početna
          </NavLink>
          <NavLink to="/o-nama" activeClassName="active">
            O nama
          </NavLink>
          <NavLink to="/usluge" activeClassName="active">
            Usluge
          </NavLink>
          <NavLink to="/kontakt" activeClassName="active">
            Kontakt
          </NavLink>
        </nav>{" "}
        <div className="social-media">
          <a
            href="https://www.facebook.com/profile.php?id=100077656701741"
            target="_blank"
          >
            <img src={facebook} alt="facebook" />
          </a>
          <a href="https://www.instagram.com/ddipranjetepiha/" target="_blank">
            <img src={instagram} alt="instagram" target="_blank" />
          </a>
          <a href="tel:+385955323189">
            <img src={whatsupp} alt="whatsupp" target="_blank" />
          </a>
        </div>
        <div className="contact-info">
          <a className="contact-info-item" href="tel:+385955323189">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path
                fillRule="evenodd"
                d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                clipRule="evenodd"
              />
            </svg>
            <span>+385 91 954 2007 </span>
          </a>
          <a
            className="contact-info-item"
            href="mailto:ddipranjetepiha@gmail.com"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
              <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
            </svg>
            <span>ddipranjetepiha@gmail.com</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
