"use client";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/placesCarousel.scss";

const PlacesCarousel = ({ places }) => {
  const [currentPlace, setCurrentPlace] = useState(
    places?.length === 0 ? 0 : 1
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentPlace === places?.length - 1) {
        setCurrentPlace(0);
      } else {
        setCurrentPlace(currentPlace + 1);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [currentPlace]);

  const getTranslateValue = () => {
    const offset = -70;
    console.log(15 + currentPlace * offset);
    return `${15 + currentPlace * offset}vw`;
  };

  return (
    <div
      className="places__carousel__container"
      data-aos="slide-up"
      data-aos-duration="400"
    >
      {" "}
      <div className="title">
        <h4>GALERIJA</h4>
        <h2>POGLEDAJTE SLIKE</h2>
      </div>
      <div
        className="places__carousel"
        style={{ transform: `translateX(${getTranslateValue()})` }}
      >
        {places?.map((place, index) => (
          <div
            className={index === currentPlace ? "place active" : "place"}
            key={index}
          >
            <div className="place__image">
              <img src={place?.img} alt="asdasd" />
            </div>
          </div>
        ))}
      </div>
      <button
        className="places__carousel__button__left"
        onClick={() => {
          if (currentPlace === 0) {
            setCurrentPlace(places?.length - 1);
          } else {
            setCurrentPlace(currentPlace - 1);
          }
        }}
      ></button>
      <button
        className="places__carousel__button__right"
        onClick={() => {
          if (currentPlace === places?.length - 1) {
            setCurrentPlace(0);
          } else {
            setCurrentPlace(currentPlace + 1);
          }
        }}
      ></button>
      <div className="places__carousel__buttons">
        {places?.map((place, index) => (
          <button
            key={index}
            onClick={() => setCurrentPlace(index)}
            className={index === currentPlace ? " active" : ""}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default PlacesCarousel;
