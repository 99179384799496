import React, { useState, useEffect } from "react";
import heroImage from "../images/hero-image.webp";
import adboutUsImage from "../images/about-us-image.jpg";
import "../styles/home.scss";
import bubles from "../images/bubles.svg";
import PlacesCarousel from "../components/PlacesCarousel";
import ContactForm from "../components/ContactForm";
import Aos from "aos";
import "aos/dist/aos.css";
import korak1 from "../images/tehnix/korak-1.jpg";
import korak2 from "../images/tehnix/korak-2.jpg";
import korak3 from "../images/tehnix/korak-3.jpg";
import korak4 from "../images/tehnix/korak-4.webp";
import korak5 from "../images/tehnix/korak-5.jpg";
import korak6 from "../images/tehnix/korak-6.jpg";
import before from "../images/before.jpg";
import after from "../images/after.jpg";
import kombi from "../images/kombi.jpg";
import g1 from "../images/gallery/1.jpg";
import g2 from "../images/gallery/2.jpg";

const Home = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [activeReview, setActiveReview] = useState(0);
  const [activeQuestion, setActiveQuestion] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({ duration: 1700 });
  }, []);

  //make that reviews are changing every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      if (activeReview === reviews.length - 1) {
        setActiveReview(0);
      } else {
        setActiveReview(activeReview + 1);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [activeReview]);

  let steps = [
    {
      img: korak1,
      title: "ISTRESANJE TEPIHA",
      content:
        "Tepih se pažljivo istresa kako bi se uklonila prašina, krupne nečistoće i čestice koje se zadržavaju dublje u vlaknima. Ovaj korak osigurava temeljitu pripremu tepiha za daljnje čišćenje.",
    },

    {
      img: korak2,
      title: "PRANJE TEPIHA",
      content:
        "Tepih se obrađuje profesionalnim strojem za pranje, koristeći odabrane deterdžente ili sredstva za čišćenje. Strojno pranje tepiha omogućava dubinsko čišćenje vlakana tepiha, uklanjanje mrlja i osvježavanje boja.",
    },

    {
      img: korak3,
      title: "CENTRIFUGIRANJE TEPIHA",
      content:
        "Nakon pranja, tepih se stavlja u centrifugu kako bi se uklonio višak vode. Ovaj korak pomaže u smanjenju vremena sušenja i osigurava brže vraćanje tepiha u upotrebu.",
    },

    {
      img: korak4,
      title: "SUŠENJE TEPIHA U KOMORI",
      content:
        "Tepih se postavlja u sušionicu gdje je kontrolirana temperatura i vlažnost zraka. Ovaj proces omogućuje da su vaši tepisi u potpunosti suhi u vrlo kratkom vremenskom periodu.",
    },

    {
      img: korak5,
      title: "ZAVRŠNO USISAVANJE TEPIHA",
      content:
        "Nakon sušenja, tepih prolazi kroz proces završnog usisavanja kako bi se uklonile eventualne preostale čestice ili vlakna. Ovaj korak doprinosi besprijekornom izgledu tepiha.",
    },
    {
      img: korak6,
      title: "PAKIRANJE TEPIHA",
      content:
        "Konačno, čist tepih pažljivo se pakira kako bi se očuvala njegova svježina i spriječila nečistoća tijekom transporta. Pakiranje osigurava da tepih bude spreman za vraćanje u savršeno čistome stanju.",
    },
  ];

  let gallery = [{ img: g2 }, { img: g1 }];

  let questions = [
    {
      title: "Šta sve nudi naša firma?",
      content:
        "Naša firma nudi širok spektar usluga, uključujući profesionalno čišćenje, održavanje objekata, i specijalizirane usluge pranja za različite vrste tekstila.",
    },
    {
      title: "Zašto smo drugačiji od ostalih?",
      content:
        "Ističemo se zbog našeg pristupa kvaliteti, pažnje na detalje i prilagođavanja usluga specifičnim potrebama svakog klijenta.",
    },
    {
      title: "Kolko se čeka na uslugu pranja tepiha?",
      content:
        "Vrijeme čekanja za uslugu pranja tepiha obično je od 2 do 3 radna dana, ovisno o trenutnom opterećenju i veličini tepiha.",
    },

    {
      title: "Kako se kreću naše cijene?",
      content:
        "Naše cijene su konkurentne i bazirane na vrsti i obimu usluge, materijalima koji se koriste, i individualnim zahtjevima klijenata.",
    },
  ];

  const nextReview = () => {
    if (activeReview === reviews.length - 1) {
      setActiveReview(0);
    } else {
      setActiveReview(activeReview + 1);
    }
  };

  const prevReview = () => {
    if (activeReview === 0) {
      setActiveReview(reviews.length - 1);
    } else {
      setActiveReview(activeReview - 1);
    }
  };

  let reviews = [
    {
      name: "Zvjezdana Kos",
      content:
        "“Brzina reakcije, Kvaliteta, Profesionalnost, Točnost, Vrijednost”",
    },
    {
      name: "Damjan Radić",
      content: "“Brza i kvalitetna usluga”",
    },
  ];

  return (
    <div className="page home">
      <div className="hero">
        <img src={korak3} alt="hero" className="hero-image" />
        <div className="hero-content ">
          <h4 data-aos="fade-up" data-aos-duration="400">
            {" "}
            GRAD ZAGREB
          </h4>
          <h2 data-aos="fade-up" data-aos-duration="400">
            STROJNO DUBINSKO <span>PRANJE TEPIHA</span>
          </h2>
          <p data-aos="fade-up" data-aos-duration="400">
            Naša firma je specijalizirana za profesionalno i dubinsko pranje
            tepiha, koristeći najnovije tehnike i ekološki prihvatljive
            proizvode.Ponosimo se brzom i efikasnom uslugom, osiguravajući da
            vaši tepisi budu čisti, svježi i suhi u najkraćem mogućem
            roku.Pružamo besplatnu uslugu preuzimanja i dostave tepiha, čineći
            cijeli proces pranja bezbrižnim i jednostavnim za naše klijente.
            Uslugu vršimo na području Zagrebačkoj županiji i Velikoj Gorici.
          </p>
          <div className="prices">
            <div className="price">
              <div className="price-per-meter">
                <h5>
                  TEPIHA DO 3m<sup>2</sup> PO KOMADU
                </h5>
                <h3>15 €</h3>
              </div>
            </div>
            <div className="price">
              <div className="price-per-meter">
                <h5>
                  {" "}
                  TEPIHA IZNAD 3m<sup>2</sup> PO m<sup>2</sup>
                </h5>
                <h3>5 €</h3>
              </div>
            </div>
            <div className="price">
              <div className="price-per-meter">
                <h5>TEPIH VUNA PO m2</h5>
                <h3>7 €</h3>
              </div>
            </div>
          </div>
          <h4>POPUST 10% ODOBRAVAMO NA RAČUN IZNAD 70 €</h4>
          <span className="note">*u cijenu je uračunat PDV.</span>
        </div>

        <div className="hero-footer">
          <div
            className="hero-footer-item"
            data-aos="slide-up"
            data-aos-duration="400"
          >
            <h3>10 000 +</h3>
            <h5>ZADOVOLJNIH KLIJENATA</h5>
          </div>

          <div
            className="hero-footer-item"
            data-aos="slide-up"
            data-aos-duration="400"
          >
            <p>
              S ponosom ističemo da smo do sada uslužili preko 10,000
              zadovoljnih klijenata, koji su nam ukazali svoje povjerenje za
              profesionalno čišćenje tepiha. Svaki od njih je doživio
              transformaciju svojih tepiha, vraćajući ih u stanje koje oduzima
              dah.
            </p>
          </div>
        </div>

        <div
          className="hero-free-delivery"
          data-aos="fade-up"
          data-aos-duration="400"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6"
          >
            <path d="M3.375 4.5C2.339 4.5 1.5 5.34 1.5 6.375V13.5h12V6.375c0-1.036-.84-1.875-1.875-1.875h-8.25ZM13.5 15h-12v2.625c0 1.035.84 1.875 1.875 1.875h.375a3 3 0 1 1 6 0h3a.75.75 0 0 0 .75-.75V15Z" />
            <path d="M8.25 19.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0ZM15.75 6.75a.75.75 0 0 0-.75.75v11.25c0 .087.015.17.042.248a3 3 0 0 1 5.958.464c.853-.175 1.522-.935 1.464-1.883a18.659 18.659 0 0 0-3.732-10.104 1.837 1.837 0 0 0-1.47-.725H15.75Z" />
            <path d="M19.5 19.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z" />
          </svg>

          <h4>BESPLATNA DOSTAVA</h4>
        </div>
        <a
          className="telephone"
          data-aos="fade-up"
          data-aos-duration="400"
          href="tel:+385919542007"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6"
          >
            <path
              fillRule="evenodd"
              d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
              clipRule="evenodd"
            />
          </svg>

          <h4>+385 91 954 2007</h4>
        </a>
      </div>

      <div className="reviews">
        <button className="prev" onClick={() => prevReview()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5 8.25 12l7.5-7.5"
            />
          </svg>
        </button>
        <button className="next" onClick={() => nextReview()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </button>

        <img
          className="bubles"
          src={bubles}
          alt="bubles"
          data-aos="slide-up"
          data-aos-duration="400"
          data-aos-delay="200"
        />
        <h3 data-aos="fade-up" data-aos-duration="400" data-aos-delay="200">
          {reviews[activeReview].content}
        </h3>
        <h7 data-aos="fade-up" data-aos-duration="400" data-aos-delay="200">
          {reviews[activeReview].name}
        </h7>
        <div
          className="google"
          data-aos="fade-up"
          data-aos-duration="400"
          data-aos-delay="200"
        >
          <h6>Google Reviews</h6>
          <span>★★★★★</span> <p>5.0</p>
        </div>
      </div>
      <div className="about-us">
        {" "}
        <img
          className="bubles"
          data-aos="slide-up"
          data-aos-duration="400"
          src={bubles}
          alt="bubles"
        />
        <div
          className="about-us-image"
          data-aos="fade-up"
          data-aos-duration="400"
        >
          <img src={adboutUsImage} alt="hero" />
        </div>
        <div className="about-us-content">
          <h4 data-aos="fade-up" data-aos-duration="400">
            SAZNAJTE VIŠE O NAMA
          </h4>
          <h2 data-aos="fade-up" data-aos-duration="400">
            O NAMA
          </h2>
          <p data-aos="fade-up" data-aos-duration="400">
            Naša firma je specijalizirana za profesionalno i dubinsko pranje
            tepiha, koristeći najnovije tehnike i ekološki prihvatljive
            proizvode.Ponosimo se brzom i efikasnom uslugom, osiguravajući da
            vaši tepisi budu čisti, svježi i suhi u najkraćem mogućem
            roku.Pružamo besplatnu uslugu preuzimanja i dostave tepiha, čineći
            cijeli proces pranja bezbrižnim i jednostavnim za naše klijente.
            Uslugu vršimo na području Zagrebačkoj županiji i Velikoj Gorici.
          </p>
          <div
            className="about-us-content-rows"
            data-aos="fade-up"
            data-aos-duration="400"
          >
            <div className="about-us-content-row">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M12 1.5a5.25 5.25 0 0 0-5.25 5.25v3a3 3 0 0 0-3 3v6.75a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3v-6.75a3 3 0 0 0-3-3v-3c0-2.9-2.35-5.25-5.25-5.25Zm3.75 8.25v-3a3.75 3.75 0 1 0-7.5 0v3h7.5Z"
                  clipRule="evenodd"
                />
              </svg>
              <span>POSVEĆENOST TEPISIMA </span>
            </div>{" "}
            <div className="about-us-content-row">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M19.902 4.098a3.75 3.75 0 0 0-5.304 0l-4.5 4.5a3.75 3.75 0 0 0 1.035 6.037.75.75 0 0 1-.646 1.353 5.25 5.25 0 0 1-1.449-8.45l4.5-4.5a5.25 5.25 0 1 1 7.424 7.424l-1.757 1.757a.75.75 0 1 1-1.06-1.06l1.757-1.757a3.75 3.75 0 0 0 0-5.304Zm-7.389 4.267a.75.75 0 0 1 1-.353 5.25 5.25 0 0 1 1.449 8.45l-4.5 4.5a5.25 5.25 0 1 1-7.424-7.424l1.757-1.757a.75.75 0 1 1 1.06 1.06l-1.757 1.757a3.75 3.75 0 1 0 5.304 5.304l4.5-4.5a3.75 3.75 0 0 0-1.035-6.037.75.75 0 0 1-.354-1Z"
                  clipRule="evenodd"
                />
              </svg>

              <span> PROFESIONALNO OSOBOLJE </span>
            </div>{" "}
            <div className="about-us-content-row">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M11.42 15.17 17.25 21A2.652 2.652 0 0 0 21 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 1 1-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 0 0 4.486-6.336l-3.276 3.277a3.004 3.004 0 0 1-2.25-2.25l3.276-3.276a4.5 4.5 0 0 0-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437 1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008Z"
                />
              </svg>

              <span> NAJMODERNIJI STROJEVI </span>
            </div>
            <div className="about-us-content-row">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>

              <span> EKOLOŠKI PROIZVODI </span>
            </div>
          </div>
          <div
            className="about-us-content-years"
            data-aos="fade-up"
            data-aos-duration="400"
          >
            <h3>3+</h3>
            <h4>GODINA ISKUSTVA</h4>
          </div>
        </div>
      </div>
      <div className="free-delivery-section">
        <img src={kombi} alt="hero" />
        <div className="free-delivery-section-content">
          <h2 data-aos="fade-up" data-aos-duration="400">
            BESPLATNA <span>DOSTAVA</span>
          </h2>
          <p>
            Besplatna dostava na području Zagrebačke županije i Velike Gorice.
          </p>
        </div>
      </div>
      <div className="content-1">
        {" "}
        <img src={after} alt="content-1" />
        <div
          className="content-1-content"
          data-aos="fade-up"
          data-aos-duration="400"
        >
          <div className="container">
            <h4>PRIJE I POSLJE</h4>
            <h2>
              OVAKO MOŽE IZGLEDATI VAŠ<span> TEPIH</span>
            </h2>
            <p>
              Prije čišćenja: Tepisi prije dubinskog i strojnog pranja često
              izgledaju umorno i beživotno. Boje su im izblijedjele, a vlakna
              prigušena dugotrajnim nakupljanjem prašine, mrlja i raznih
              nečistoća. Često se na površini tepiha mogu uočiti tragovi hodanja
              i sjedanja koji dodatno naglašavaju zapušten izgled. Osim toga,
              tepisi mogu imati i neugodne mirise koji su posljedica dugotrajnog
              izloženosti različitim vanjskim faktorima, poput kućnih ljubimaca,
              hrane i tekućina. Sve to čini tepih manje privlačnim i smanjuje
              ugođaj prostorije u kojoj se nalazi.
            </p>{" "}
            <p>
              Nakon čišćenja: Nakon profesionalnog strojnog i dubinskog pranja,
              tepisi dobivaju potpuno novi izgled. Boje postaju živopisne i
              svježe, vraćajući tepihu njegovu originalnu ljepotu. Vlakna tepi
              ha postaju mekša i ponovno dobivaju svoj prirodni sjaj. Nečistoće,
              mrlje i tragovi hodanja nestaju, ostavljajući tepih čistim i
              higijenski prihvatljivim. Također, neugodni mirisi se uklanjaju,
              ostavljajući prostoriju ugodnijom za boravak. Dubinsko čišćenje
              također doprinosi produženju vijeka trajanja tepiha, čuvajući
              njegovu strukturu i kvalitetu. Sve u svemu, tepih nakon našeg
              tretmana izgleda kao nov, pružajući osjećaj svježine i čistoće u
              svakom prostoru.
            </p>
          </div>
        </div>{" "}
        <img src={before} alt="content-1" />
      </div>

      <div className="making-steps">
        <div className="title">
          <h4 data-aos="fade-up" data-aos-duration="400">
            PROCEDURA
          </h4>
          <h2 data-aos="fade-up" data-aos-duration="400">
            <span> KAKO </span> MI TO RADIMO
          </h2>
        </div>

        <div className="making-steps-content">
          <div className="image" data-aos="fade-up" data-aos-duration="400">
            <img src={steps[activeStep].img} alt="hero" />
          </div>
          <div className="steps" data-aos="fade-up" data-aos-duration="400">
            {steps.map((step, index) => {
              return (
                <div
                  className={activeStep === index ? "step active" : "step"}
                  onMouseEnter={() => setActiveStep(index)}
                >
                  <div className="row-1">
                    <h2>0{index + 1}</h2> <h2>{step.title}</h2>
                  </div>
                  <div className="row-2">
                    <p>{step.content}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <PlacesCarousel places={gallery} />
      <ContactForm />
      <div className="questions">
        <div className="title">
          <h4 data-aos="slide-up" data-aos-duration="400">
            ČESTA PITANJA
          </h4>
          <h2 data-aos="slide-up" data-aos-duration="400">
            IMATE PITANJA?
          </h2>
        </div>
        <div className="questions-container">
          {questions.map((question, index) => {
            return (
              <div
                className="question"
                data-aos="slide-up"
                data-aos-duration="400"
              >
                <button
                  className="question-button"
                  onClick={() => setActiveQuestion(index)}
                >
                  <span className={activeQuestion === index ? "active" : ""}>
                    {question.title}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                <p className={activeQuestion === index ? "active" : ""}>
                  {question.content}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Home;
