import React, { useEffect } from "react";
import adboutUsImage from "../images/about-us-image.jpg";
import bubles from "../images/bubles.svg";
import "../styles/Page.scss";
import Aos from "aos";
import "aos/dist/aos.css";

const AboutUs = () => {
  useEffect(() => {
    Aos.init({ duration: 1700 });
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="page">
      <div className="about-us" data-aos="slide-up" data-aos-duration="400">
        {" "}
        <img className="bubles" src={bubles} alt="bubles" />
        <div className="about-us-image">
          <img src={adboutUsImage} alt="hero" />
        </div>
        <div className="about-us-content">
          <h4>SAZNAJTE VIŠE O NAMA</h4>
          <h2>O NAMA</h2>
          <p>
            Naša firma je specijalizirana za profesionalno i dubinsko pranje
            tepiha, koristeći najnovije tehnike i ekološki prihvatljive
            proizvode.Ponosimo se brzom i efikasnom uslugom, osiguravajući da
            vaši tepisi budu čisti, svježi i suhi u najkraćem mogućem
            roku.Pružamo besplatnu uslugu preuzimanja i dostave tepiha, čineći
            cijeli proces pranja bezbrižnim i jednostavnim za naše klijente.
            Uslugu vršimo na području Zagrebačkoj županiji i Velikoj Gorici.
          </p>
          <div
            className="about-us-content-rows"
            data-aos="fade-up"
            data-aos-duration="400"
          >
            <div className="about-us-content-row">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M12 1.5a5.25 5.25 0 0 0-5.25 5.25v3a3 3 0 0 0-3 3v6.75a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3v-6.75a3 3 0 0 0-3-3v-3c0-2.9-2.35-5.25-5.25-5.25Zm3.75 8.25v-3a3.75 3.75 0 1 0-7.5 0v3h7.5Z"
                  clipRule="evenodd"
                />
              </svg>
              <span>POSVEĆENOST TEPISIMA </span>
            </div>{" "}
            <div className="about-us-content-row">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M19.902 4.098a3.75 3.75 0 0 0-5.304 0l-4.5 4.5a3.75 3.75 0 0 0 1.035 6.037.75.75 0 0 1-.646 1.353 5.25 5.25 0 0 1-1.449-8.45l4.5-4.5a5.25 5.25 0 1 1 7.424 7.424l-1.757 1.757a.75.75 0 1 1-1.06-1.06l1.757-1.757a3.75 3.75 0 0 0 0-5.304Zm-7.389 4.267a.75.75 0 0 1 1-.353 5.25 5.25 0 0 1 1.449 8.45l-4.5 4.5a5.25 5.25 0 1 1-7.424-7.424l1.757-1.757a.75.75 0 1 1 1.06 1.06l-1.757 1.757a3.75 3.75 0 1 0 5.304 5.304l4.5-4.5a3.75 3.75 0 0 0-1.035-6.037.75.75 0 0 1-.354-1Z"
                  clipRule="evenodd"
                />
              </svg>

              <span> PROFESIONALNO OSOBOLJE </span>
            </div>{" "}
            <div className="about-us-content-row">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M11.42 15.17 17.25 21A2.652 2.652 0 0 0 21 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 1 1-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 0 0 4.486-6.336l-3.276 3.277a3.004 3.004 0 0 1-2.25-2.25l3.276-3.276a4.5 4.5 0 0 0-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437 1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008Z"
                />
              </svg>

              <span> NAJMODERNIJI STROJEVI </span>
            </div>
            <div className="about-us-content-row">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>

              <span> EKOLOŠKI PROIZVODI </span>
            </div>
          </div>
          <div className="about-us-content-years">
            <h3>3+</h3>
            <h4>GODINA ISKUSTVA</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
