import React from "react";
import "../styles/Footer.scss";
import logo from "../images/logo-white.svg";
import facebook from "../icons/facebook.svg";
import instagram from "../icons/instagram.svg";
import whatsupp from "../icons/whatsupp.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-sections">
        <div className="footer-section">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>{" "}
          <p>
            Naša firma je specijalizirana za profesionalno i dubinsko pranje
            tepiha, koristeći najnovije tehnike i ekološki prihvatljive
            proizvode.Ponosimo se brzom i efikasnom uslugom, osiguravajući da
            vaši tepisi budu čisti, svježi i suhi u najkraćem mogućem
            roku.Pružamo besplatnu uslugu preuzimanja i dostave tepiha, čineći
            cijeli proces pranja bezbrižnim i jednostavnim za naše klijente.
          </p>
          <div className="socials">
            <a
              href="https://www.facebook.com/profile.php?id=100077656701741"
              target="_blank"
            >
              <img src={facebook} alt="facebook" />
            </a>

            <a
              href="https://www.instagram.com/ddipranjetepiha/"
              target="_blank"
            >
              <img src={instagram} alt="instagram" />
            </a>

            <a href="https://wa.me/+385919542007" target="_blank">
              <img src={whatsupp} alt="whatsupp" />
            </a>
          </div>
        </div>
        <div className="footer-section">
          <h5 className="footer-section-title">KONTAKT INFORMACIJE</h5>
          <div className="footer-section-links">
            <a href="tel:+385919542007">Broj Telefona: +385 91 954 2007 </a>
            <a
              href="https://maps.app.goo.gl/Mnqyq2A5RhoLZ4TB6"
              target="_blank"
              rel="noreferrer"
            >
              Adresa: Sopnička ul. 3, 10360, Sesvete
            </a>
            <a href="mailto:ddipranjetepiha@gmail.com">
              Email: ddipranjetepiha@gmail.com
            </a>
          </div>
        </div>
        <div className="footer-section">
          <h5 className="footer-section-title">SEKCIJE NA STRANICI</h5>
          <div className="footer-section-links">
            <Link to="/">Početna</Link>
            <Link to="/o-nama">O nama</Link>
            <Link to="/usluge">Usluge</Link>
            <Link to="/kontakt">Kontakt</Link>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <span className="center">
          © 2024 ČIŠĆENJE TEPIHA SESVETE. All Rights Reserved.
        </span>
        <a href="https://msk.hr/" target="_blank" rel="noreferrer">
          Izradba web stranice: <span>MSK.HR</span>
        </a>
      </div>
    </div>
  );
};

export default Footer;
