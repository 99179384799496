import React, { useEffect } from "react";
import ContactForm from "../components/ContactForm";
import "../styles/Page.scss";
import contactImage from "../images/contact.webp";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="page contact">
      <ContactForm />
    </div>
  );
};

export default Contact;
