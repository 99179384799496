import React, { useState, useEffect } from "react";
import "../styles/Page.scss";
import bubles from "../images/bubles.svg";
import adboutUsImage from "../images/about-us-image.jpg";
import PlacesCarousel from "../components/PlacesCarousel";
import heroImage from "../images/hero-image.webp";
import Aos from "aos";
import "aos/dist/aos.css";
import korak1 from "../images/tehnix/korak-1.jpg";
import korak2 from "../images/tehnix/korak-2.jpg";
import korak3 from "../images/tehnix/korak-3.jpg";
import korak4 from "../images/tehnix/korak-4.webp";
import korak5 from "../images/tehnix/korak-5.jpg";
import korak6 from "../images/tehnix/korak-6.jpg";
import g1 from "../images/gallery/1.jpg";
import g2 from "../images/gallery/2.jpg";

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({ duration: 1700 });
  }, []);
  let gallery = [{ img: g2 }, { img: g1 }];

  const [activeStep, setActiveStep] = useState(0);
  let steps = [
    {
      img: korak1,
      title: "ISTRESANJE TEPIHA",
      content:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    },

    {
      img: korak2,
      title: "PRANJE TEPIHA",
      content:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    },

    {
      img: korak3,
      title: "CENTRIFUGIRANJE TEPIHA",
      content:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    },

    {
      img: korak4,
      title: "SUŠENJE TEPIHA U KOMORI",
      content:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    },

    {
      img: korak5,
      title: "ZAVRŠNO USISAVANJE TEPIHA",
      content:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    },
    {
      img: korak6,
      title: "PAKIRANJE TEPIHA",
      content:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    },
  ];
  return (
    <div className="page">
      <div className="making-steps">
        <div className="title">
          <h4 data-aos="slide-up" data-aos-duration="400">
            PROCEDURA
          </h4>
          <h2 data-aos="slide-up" data-aos-duration="400">
            KAKO <span>MI</span> TO RADIMO
          </h2>
        </div>

        <div className="making-steps-content">
          <div className="image" data-aos="slide-up" data-aos-duration="400">
            <img src={steps[activeStep].img} alt="hero" />
          </div>
          <div className="steps" data-aos="slide-up" data-aos-duration="400">
            {steps.map((step, index) => {
              return (
                <div
                  className={activeStep === index ? "step active" : "step"}
                  onMouseEnter={() => setActiveStep(index)}
                >
                  <div className="row-1">
                    <h2>0{index + 1}</h2> <h2>{step.title}</h2>
                  </div>
                  <div className="row-2">
                    <p>{step.content}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <PlacesCarousel places={gallery} />
    </div>
  );
};

export default Services;
