"use client";
import React, { useState, useEffect } from "react";
import logo from "../images/logo.svg";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin,
  InfoWindow,
} from "@vis.gl/react-google-maps";

const MapPoint = () => {
  const apiKey = "AIzaSyBY4N44VVf8Zv5b5bnMQSj7p-MXK_kH8gI";

  //45.81704645174405, 16.1007252913146
  const position = {
    lat: 45.81704645174405,
    lng: 16.1007252913146,
  };

  return (
    <div className="contact-map">
      <APIProvider apiKey={process.env.NEXT_APP_GOOGLE_MAPS_API_KEY || apiKey}>
        <div className="map-container">
          <Map zoom={15} center={position} mapId={"1566776688d7cfaf"}>
            <AdvancedMarker
              position={position}
              onClick={(e) => {
                window.open("https://maps.app.goo.gl/zz22VCggGuFGvPCdA");
              }}
            >
              <Pin />
            </AdvancedMarker>
          </Map>
        </div>
      </APIProvider>
    </div>
  );
};

export default MapPoint;
