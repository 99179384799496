import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Layout from "./components/Layout";
import Contact from "./pages/Contact";
import AboutUs from "./pages/AboutUs";
import Services from "./pages/Services";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/*" element={<h1> Not Found </h1>} />
          <Route index element={<Home />} />
          <Route path="/kontakt" element={<Contact />} />
          <Route path="/o-nama" element={<AboutUs />} />
          <Route path="/usluge" element={<Services />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
