import React, { useEffect, useRef } from "react";
import whatsUpp from "../icons/whatsupp.svg";
import "../styles/ContactForm.scss";
import { Link } from "react-router-dom";
import MapPoint from "./MapPoint";
import Aos from "aos";
import "aos/dist/aos.css";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
  useEffect(() => {
    Aos.init({ duration: 1700 });
  }, []);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_qmslxiw",
        "template_fehwrgc",
        form.current,
        "mtd5i1kHo1o6AZeMR"
      )
      .then(
        (result) => {
          alert("Uspješno ste poslali poruku!");
        },
        (error) => {
          alert("Došlo je do greške. Pokušajte ponovno.");
        }
      );
  };
  return (
    <div className="contact-form">
      <div className="form-container">
        <div className="title">
          <h4 data-aos="fade-up" data-aos-duration="400">
            POŠALJITE NAM PORUKU
          </h4>
          <h2 data-aos="fade-up" data-aos-duration="400">
            KONTAKTIRAJTE NAS
          </h2>
        </div>

        <form
          className="form"
          data-aos="fade-up"
          data-aos-duration="400"
          ref={form}
          onSubmit={sendEmail}
        >
          <div className="form-row">
            <div className="input-container">
              <span>Ime</span>
              <input type="text" placeholder="Ime" id="name" name="name" />
            </div>
            <div className="input-container">
              <span>Prezime</span>
              <input
                type="text"
                placeholder="Prezime"
                id="surname"
                name="surname"
              />
            </div>{" "}
          </div>{" "}
          <div className="form-row">
            <div className="input-container">
              <span>E-mail</span>
              <input type="text" placeholder="E-mail" id="email" name="email" />
            </div>
            <div className="input-container">
              <span>Broj telefona</span>
              <input
                type="text"
                placeholder="Broj telefona"
                id="phone"
                name="phone"
              />
            </div>{" "}
          </div>{" "}
          <div className="form-row">
            <div className="input-container">
              <span>Poruka</span>
              <textarea placeholder="Poruka" id="message" name="message" />
            </div>
          </div>
          <div className="form-row" data-aos="fade-up" data-aos-duration="400">
            {" "}
            <div className="input-container">
              <button type="submit" className="button">
                Pošalji poruku
              </button>
            </div>
          </div>
        </form>

        <a
          className="whats-upp"
          href="https://wa.me/385919542007"
          data-aos="fade-up"
          data-aos-duration="400"
        >
          <img src={whatsUpp} alt="whatsupp" />
          <div className="whats-upp-text">
            <h3>KONTAKTIRAJTE NAS PREKO WHATSAPP-A</h3>
            <span>+385 91 954 2007</span>
          </div>
        </a>
      </div>
      <MapPoint />
    </div>
  );
};

export default ContactForm;
